<template>
    <v-container fluid class="down-top-padding">
<!--        <BaseBreadcrumb :title="trans('router.admin.promoCodeItems')" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>-->
        <v-row>
            <v-col cols="12" sm="12">
                <user-table></user-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "items",
        components: {
            // BaseBreadcrumb: () => import("@/components/commonComponents/BaseBreadcrumb"),
            UserTable: () => import("@/components/master/user/UserTable")
        },
        data(){
            return {
                loading: false,
                errors: null,
                // breadcrumbs: [
                //     {
                //         text: this.trans("router.admin.admin"),
                //         disabled: false,
                //         to: {name:'admin'}
                //     },
                //     {
                //         text: this.trans("router.admin.promoCodes"),
                //         disabled: false,
                //         to: {name:'admin.promoCodes'}
                //     },
                //     {
                //         text: this.trans("router.admin.promoCodeItems"),
                //         disabled: true,
                //         to: {name:'admin.promoCodes.items'}
                //     }
                // ]
            }
        },
    }
</script>

